import React, { ReactChild } from "react";
import {
  Persona,
  mergeStyleSets,
  PersonaInitialsColor,
  IPersonaProps,
  PersonaSize,
  Stack,
  IStackStyles,
  IButtonStyles,
  Toggle,
  ITheme,
  DefaultButton,
} from "@fluentui/react";

import { useOutsideClick, useAppTheme } from "../../utils/hooks";
import CILogo from "../../assets/cilogo.png";

import { User } from "../../types";

const topBarStyles = (theme: ITheme) =>
  mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      padding: 0,
      margin: 0,
      overflow: "hidden",
    },

    header: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: theme.palette?.themeSecondary,
      color: theme.palette?.white,
      height: "46px",
      padding: "0px 10px",
    },
    brand: {
      height: 100,
      width: 120,
      flexWrap: "nowrap",
      marginRight: 20,
    },
    optionContainer: {
      display: "flex",
    },
    topNav: {
      display: "flex",
      flexWrap: "nowrap",
      flexGrow: 1,
      height: "100%",
    },

    menuIcon: {
      display: "inline-block",
      color: "#b2edb6",
      margin: "auto",
    },
    profileButton: {
      position: "relative",
    },
    profileContainer: {
      position: "absolute",
      right: "15px",
      top: "38px",
      width: "320px",
      backgroundColor: theme.palette?.white,
      boxShadow: "0px 8px 16px 0 silver",
      zIndex: 100,
    },
  });

export function TopBar({
  user,
  onLogout,
  children,
}: {
  user: User;
  onLogout: () => void;
  children: ReactChild;
}) {
  const {
    ref,
    isVisible: isDropdown,
    setIsVisible: setDropdown,
  } = useOutsideClick(false);

  const { selectedTheme, selectedThemeName, setTheme } = useAppTheme();
  const classes = topBarStyles(selectedTheme as ITheme);

  const { email, username } = user;
  const buttonStyles: IButtonStyles = {
    root: {
      minHeight: "35px",
      borderColor: selectedTheme.palette.white,
    },
    rootHovered: {
      backgroundColor: selectedTheme.palette.neutralQuaternary,
    },
    label: {
      fontWeight: 400,
    },
  };

  return (
    <div className={classes.header}>
      <img src={CILogo} style={{ height: 30 }} />
      <Stack horizontal verticalAlign={"center"}>
        {/* <Toggle
          defaultChecked={selectedThemeName === "dark"}
          styles={{ root: { marginBottom: "0px" } }}
          onText="Dark"
          offText="Light"
          onChange={(e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            setTheme(checked ? "dark" : "light");
          }}
        /> */}
        <div ref={ref} className={classes.profileButton}>
          <Persona
            initialsColor={selectedTheme.palette.neutralPrimaryAlt}
            {...sharedPersonaProps}
            text={username}
            onClick={() => setDropdown(!isDropdown)}
          />
          <div
            className={classes.profileContainer}
            style={{ display: isDropdown ? "block" : "none" }}
          >
            <Stack>
              <Stack horizontal horizontalAlign="end">
                <DefaultButton
                  styles={buttonStyles}
                  text="Sign out"
                  onClick={onLogout}
                >
                  SignOut
                </DefaultButton>
              </Stack>
              <Stack
                horizontal
                styles={profileStackStyles}
                verticalAlign="center"
                horizontalAlign="space-around"
              >
                <Persona
                  initialsColor={PersonaInitialsColor.coolGray}
                  {...profilePersonaProps}
                  text={username}
                />
                <Stack
                  styles={{ root: { color: selectedTheme.palette.black } }}
                >
                  <span>{username}</span>
                  <span>{email}</span>
                </Stack>
              </Stack>
            </Stack>
          </div>
        </div>
      </Stack>
    </div>
  );
}

const profilePersonaProps: IPersonaProps = {
  size: PersonaSize.size72,
  styles: {
    primaryText: {
      display: "none",
    },
    details: {
      display: "none",
    },
  },
};

const profileStackStyles: IStackStyles = {
  root: {
    padding: "10px 15px",
    width: "100%",
  },
};

const sharedPersonaProps: IPersonaProps = {
  size: PersonaSize.size32,
  styles: {
    root: {
      cursor: "pointer",
    },
    primaryText: {
      display: "none",
    },
  },
};
