import axios from "axios";
import startsWith from "lodash/startsWith";
import { API_URI } from "./config";
// import { commonActions } from "./features/common";
import { logoutUser } from "./features/users/usersSlice";
import { StoreType } from "./store";

export const getCookie = (name: string) => {
  for (let c of (document.cookie || "").split(";")) {
    const match = c.trim().match(/(\w+)=(.*)/);
    if (match === null || match === undefined) continue;
    const [m, k, v] = Array.from(match!);
    if (m !== undefined && decodeURIComponent(k) === name) {
      return decodeURIComponent(v);
    }
  }
  return undefined;
};

export default {
  setup: (store: StoreType) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const isInternalAPI = startsWith(
          error.response.config.url || "",
          API_URI
        );
        if (
          error.response.status === 401 &&
          error.response.config.withCredentials &&
          isInternalAPI
        ) {
          store.dispatch(logoutUser());
        }

        if (error.response.status === 404) {
          // store.dispatch(commonActions.showErrorThunk("NotFound"));
        } else if (error.response.status === 500) {
          // store.dispatch(commonActions.showErrorThunk("ServerError"));
        }
        return Promise.reject(error);
      }
    );
    axios.interceptors.request.use((request) => {
      //const token = window.localStorage.getItem("token");
      const token = getCookie("csrftoken");
      // const isInternalAPI = startsWith(request.url, API_URI);
      // only inject auth headers in the case of our internal API
      if (token) {
        //  && isInternalAPI
        request.headers["X-CSRFToken"] = token;
        request.headers["credentials"] = "same-origin";
      }
      return request;
    });
  },
};
