import React, { useEffect, useState, useRef } from "react";
import { DefaultButton, Stack, Label, StackItem } from "office-ui-fabric-react";
import { IStackStyles, IStackTokens, IStackItemStyles } from "@fluentui/react";
import { TopBar } from "../components/Navigation/TopBar";
import { selectUser, userActions } from "../features/users/usersSlice";
import { useAppDispatch, useTypedSelector } from "../store";
import Mark from "mark.js";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Iannotation, IannotationDetail } from "../types";
import { Annotationlist } from "../components/Annotationlist";
import { SnomedTable } from "../components/SnomedTable";
import "react-tabs/style/react-tabs.css";
import { AppSpinner } from "../components/AppSpinner";

const stackStyles: IStackStyles = {
  root: {
    height: "100%",
    width: "100%",
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    flex: 1,
    height: "90vh",
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 1,
  padding: 1,
};

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const sampleMapper = {
  sample1:
    "Patient is a pleasant 60-year-old gentleman, who initially had a sigmoid colectomy for diverticular abscess, subsequently had a dehiscence with evisceration. Came in approximately 36 hours ago with pain across his lower abdomen. CT scan demonstrated presence of an abscess beneath the incision. I recommended to the patient he undergo the above-named procedure. Procedure, purpose, risks, expected benefits, potential complications, alternatives forms of therapy were discussed with him, and he was agreeable to surgery.",
  sample2:
    "In short, the patient is a 55-year-old gentleman with long-standing morbid obesity, resistant to nonsurgical methods of weight loss with BMI of 69.7 with comorbidities of hypertension, atrial fibrillation, hyperlipidemia, possible sleep apnea, and also osteoarthritis of the lower extremities. The patient had no type 2 diabetes.",
  sample3:
    "The patient had an x-ray of the right ankle, which interpreted by myself shows no acute fracture or dislocation. The patient have history of osteoporosis. Due to the fact this patient has no evidence of an ankle fracture, she can be safely discharged to home. She is able to walk on it without significant pain, thus I recommend rest for 1 week and follow up with the doctor if she has persistent pain. She may need to see a specialist, but at this time this is a very mild ankle injury. There is no significant physical finding, and I foresee no complications.",
  sample4:
    `Description: The patient is a 58-year-old African-American right-handed female with 16 years of education who was referred for a neuropsychological evaluation by Dr
    A comprehensive evaluation was requested to assess current cognitive functioning and assist with diagnostic decisions and treatment planning.
    (Medical Transcription Sample Report)
    REASON FOR REFERRAL: The patient is a 58-year-old African-American right-handed female with 16 years of education who was referred for a neuropsychological evaluation by Dr
    She is presenting for a second opinion following a recent neuropsychological evaluation that was ordered by her former place of employment that suggested that she was in the &quot;early stages of a likely dementia&quot; and was thereafter terminated from her position as a psychiatric nurse
    A comprehensive evaluation was requested to assess current cognitive functioning and assist with diagnostic decisions and treatment planning
    Note that this evaluation was undertaken as a clinical exam and intended for the purposes of aiding with treatment planning
    The patient was fully informed about the nature of this evaluation and intended use of the results.
    RELEVANT BACKGROUND INFORMATION: Historical information was obtained from a review of available medical records and clinical interview with the patient
    A summary of pertinent information is presented below
    Please refer to the patients medical chart for a more complete history.
    HISTORY OF PRESENTING PROBLEM: The patient reported that she had worked as a nurse supervisor for Hospital Center for four years
    She was dismissed from this position in September 2009, although she said that she is still under active status technically, but is not able to work
    She continues to receive some compensation through FMLA hours
    She said that she was told that she had three options, to resign, to apply for disability retirement, and she had 90 days to complete the process of disability retirement after which her employers would file for charges in order for her to be dismissed from State Services
    She said that these 90 days are up around the end of November
    She said the reason for her dismissal was performance complaints
    She said that they began &quot;as soon as she arrived and that these were initially related to problems with her taking too much sick time off secondary to diabetes and fibromyalgia management and at one point she needed to obtain a doctors note for any days off
    She said that her paperwork was often late and that she received discipline for not disciplining her staff frequently enough for tardiness or missed workdays
    She described it as a very chaotic and hectic work environment in which she was often putting in extra time
    She said that since September 2008 she only took two sick days and was never late to work, but that she continued to receive a lot of negative feedback.
    In July of this year, she reportedly received a letter from personnel indicating that she was being referred to a state medical doctor because she was unable to perform her job duties and due to excessive sick time
    Following a brief evaluation with this doctor whose records we do not have, she was sent to a neuropsychologist, Dr
    Y, Ph.D
    He completed a Comprehensive Independent Medical Evaluation on 08/14/2009
    She said that on 08/27/2009, she returned to see the original doctor who told her that based on that evaluation she was not able to work anymore
    Please note that we do not have copies of any of her work-related correspondence
    The patient never received a copy of the neuropsychological evaluation because she was told that it was &quot;too derogatory.&quot;  A copy of that evaluation was provided directly to this examiner for the purpose of this evaluation
    To summarize, the results indicated &quot;diagnostically, The patient presents cognitive deficits involving visual working memory, executive functioning, and motor functioning along with low average intellectual functioning that is significantly below her memory functioning and below expectation based on her occupational and academic history
    This suggests that her intellectual functioning has declined.&quot;  It concluded that &quot;results overall suggest early stages of a likely dementia or possibly the effects of diabetes, although her deficits are greater than expected for diabetes-related executive functioning problems and peripheral neuropathy…  The patient deficits within the current test battery suggest that she would not be able to safely and effectively perform the duties of a nurse supervisor without help handling documentary demands and some supervision of her visual processing
    The prognosis for improvement is not good, although she might try stimulant medication if compatible with her other
    Following her dismissal, The patient presented to her primary physician, Henry Fein, M.D., who referred her to Dr
    X for a second opinion regarding her cognitive deficits
    His neurological examination on 09/23/2009 was unremarkable
    The patient scored 20/30 on the Mini-Mental Status Exam missing one out of three words on recall, but was able to do so with prompting
    A repeat neurocognitive testing was suggested in order to assess for subtle deficits in memory and concentration that were not appreciated on this gross cognitive measure.
    IMAGING STUDIES: MRI of the brain on 09/14/2009 was unremarkable with no evidence of acute intracranial abnormality or abnormal enhancing lesions
    Note that the MRI was done with and without gadolinium contrast.
    CURRENT FUNCTIONING: The patient reported that she had experienced some difficulty completing paperwork on time due primarily to the chaoticness of the work environment and the excessive amount of responsibility that was placed upon her
    When asked about changes in cognitive functioning, she denied noticing any decline in problem solving, language, or nonverbal skills
    She also denied any problems with attention and concentration or forgetfulness or memory problems
    She continues to independently perform all activities of daily living
    She is in charge of the household finances, has had no problems paying bills on time, has had no difficulties with driving or accidents, denied any missed appointments and said that no one has provided feedback to her that they have noticed any changes in her cognitive functioning
    She reported that if her children had noticed anything they definitely would have brought it to her attention
    She said that she does not currently have a lawyer and does not intend to return to her previous physician
    She said she has not yet proceeded with the application for disability retirement because she was told that her doctors would have to fill out that paperwork, but they have not claimed that she is disabled and so she is waiting for the doctors at her former workplace to initiate the application
    Other current symptoms include excessive fatigue
    She reported that she was diagnosed with chronic fatigue syndrome in 1991, but generally symptoms are under better control now, but she still has difficulty secondary to fibromyalgia
    She also reported having fallen approximately five times within the past year
    She said that this typically occurs when she is climbing up steps and is usually related to her right foot &quot;like dragging.&quot;  Dr
    Xs physical examination revealed no appreciable focal peripheral deficits on motor or sensory testing and notes that perhaps these falls are associated with some stiffness and pain of her right hip and knee, which are chronic symptoms from her fibromyalgia and osteoarthritis
    She said that she occasionally bumps into objects, but denied noticing it happening one on any particular part of her body
    Muscle pain secondary to fibromyalgia reportedly occurs in her neck and shoulders down both arms and in her left hip.
    OTHER MEDICAL HISTORY: The patient reported that her birth and development were normal
    She denied any significant medical conditions during childhood
    As mentioned, she now has a history of fibromyalgia
    She also experiences some restriction in the range of motion with her right arm
    MRI of the C-spine 04/02/2009 showed a hemangioma versus degenerative changes at C7 vertebral body and bulging annulus with small central disc protrusion at C6-C7
    MRI of the right shoulder on 06/04/2009 showed small partial tear of the distal infraspinatus tendon and prominent tendinopathy of the distal supraspinatus tendon
    As mentioned, she was diagnosed with chronic fatigue syndrome in 1991
    She thought that this may actually represent early symptoms of fibromyalgia and said that symptoms are currently under control
    She also has diabetes, high blood pressure, osteoarthritis, tension headaches, GERD, carpal tunnel disease, cholecystectomy in 1976, and ectopic pregnancy in 1974
    Her previous neuropsychological evaluation referred to an outpatient left neck cystectomy in 2007
    She has some difficulty falling asleep, but currently typically obtains approximately seven to eight hours of sleep per night
    She did report some sleep disruption secondary to unusual dreams and thought that she talked to herself and could sometimes hear herself talking in her sleep.
    CURRENT MEDICATIONS: NovoLog, insulin pump, metformin, metoprolol, amlodipine, Topamax, Lortab, tramadol, amitriptyline, calcium plus vitamin D, fluoxetine, pantoprazole, Naprosyn, fluticasone propionate, and vitamin C.
    SUBSTANCE USE: The patient reported that she rarely drinks alcohol and she denied smoking or using illicit drugs
    She drinks two to four cups of coffee per day.
    SOCIAL HISTORY: The patient was born and raised in North Carolina
    She was the sixth of nine siblings
    Her father was a chef
    He completed third grade and died at 60 due to complications of diabetes
    Her mother is 93 years old
    Her last job was as a janitor
    She completed fourth grade
    She reported that she has no cognitive problems at this time
    Family medical history is significant for diabetes, heart disease, hypertension, thyroid problems, sarcoidosis, and possible multiple sclerosis and depression
    The patient completed a Bachelor of Science in Nursing through State University in 1979
    She denied any history of problems in school such as learning disabilities, attentional problems, difficulty learning to read, failed grades, special help in school or behavioral problems
    She was married for two years
    Her ex-husband died in 1980 from acute pancreatitis secondary to alcohol abuse
    She has two children ages 43 and 30
    Her son whose age is 30 lives nearby and is in consistent contact with her and she is also in frequent contact and has a close relationship with her daughter who lives in New York
    In school, the patient reported obtaining primarily As and Bs
    She said that her strongest subject was math while her worst was spelling, although she reported that her grades were still quite good in spelling
    The patient worked for Hospital Center for four years
    Prior to that, she worked for an outpatient mental health center for 2-1/2 years
    She was reportedly either terminated or laid off and was unsure of the reason for that
    Prior to that, she worked for Walter P
    Carter Center reportedly for 21 years
    She has also worked as an OB nurse in the past
    She reported that other than the two instances reported above, she had never been terminated or fired from a job
    In her spare time, the patient enjoys reading, participating in womens groups doing puzzles, playing computer games.
    PSYCHIATRIC HISTORY: The patient reported that she sought psychotherapy on and off between 1991 and 1997 secondary to her chronic fatigue
    She was also taking Prozac during that time
    She then began taking Prozac again when she started working at secondary to stress with the work situation
    She reported a chronic history of mild sadness or depression, which was relatively stable
    When asked about her current psychological experience, she said that she was somewhat sad, but not dwelling on things
    She denied any history of suicidal ideation or homicidal ideation.
    TASKS ADMINISTERED-
    Clinical Interview
    Adult History Questionnaire
    Wechsler Test of Adult Reading (WTAR)
    Mini Mental Status Exam (MMSE)
    Cognistat Neurobehavioral Cognitive Status Examination
    Repeatable Battery for the Assessment of Neuropsychological Status (RBANS; Form XX)
    Mattis Dementia Rating Scale, 2nd Edition (DRS-2)
    Neuropsychological Assessment Battery (NAB)
    Wechsler Adult Intelligence Scale, Third Edition (WAIS-III)
    Wechsler Adult Intelligence Scale, Fourth Edition (WAIS-IV)
    Wechsler Abbreviated Scale of Intelligence (WASI)
    Test of Variables of Attention (TOVA)
    Auditory Consonant Trigrams (ACT)
    Paced Auditory Serial Addition Test (PASAT)
    Ruff 2 & 7 Selective Attention Test
    Symbol Digit Modalities Test (SDMT)
    Multilingual Aphasia Examination, Second Edition (MAE-II)
    Token Test
    Sentence Repetition
    Visual Naming
    Controlled Oral Word Association
    Spelling Test
    Aural Comprehension
    Reading Comprehension
    Boston Naming Test, Second Edition (BNT-2)
    Animal Naming Test
    Controlled Oral Word Association Test (COWAT: F-A-S)
    The Beery-Buktenica Developmental Test of Visual-Motor Integration (VMI)
    The Beery-Buktenica Developmental Test of Motor Coordination
    The Beery-Buktenica Developmental Test of Visual Perception
    Hooper Visual Organization Test (VOT)
    Judgment of Line Orientation (JOLO)
    Rey Complex Figure Test (RCFT)
    Wechsler Memory Scale, Third Edition (WMS-III)
    Wechsler Memory Scale, Fourth Edition (WMS-IV)
    California Verbal Learning Test, Second Edition (CVLT-II)
    Rey Auditory-Verbal Learning Test (RAVLT)
    Delis-Kaplan Executive Function System (D-KEFS)
    Trail Making Test
    Verbal Fluency (Letter & Category)
    Design Fluency
    Color-Word Interference Test
    Tower
    Wisconsin Card Sorting Test (WCST)
    Stroop Color-Word Test
    Color Trails
    Trail Making Test A & B
    Wide Range Achievement Test, Fourth Edition (WRAT-IV)
    Woodcock Johnson Tests of Achievement, Third Edition (WJ-III)
    Nelson-Denny Reading Test
    Grooved Pegboard
    Purdue Pegboard
    Finger Tapping Test
    Beck Depression Inventory (BDI)
    Mood Assessment Scale (MAS)
    State-Trait Anxiety Inventory (STAI)
    Minnesota Multiphasic Personality Inventory, Second Edition (MMPI-2)
    Millon Clinical Multiaxial Inventory, Third Edition (MCMI-III)
    Millon Behavioral Medicine Diagnostic (MBMD)
    Behavior Rating Inventory of Executive Function (BRIEF)
    Adaptive Behavior Assessment System, Second Edition (ABAS-II)
    BEHAVIORAL OBSERVATIONS: The patient arrived unaccompanied and on time for her appointment
    She ambulated independently and no gross psychomotor abnormalities were noted
    Vision and hearing appeared to be adequate for testing
    She was neatly dressed and groomed
    Eye contact was good
    She was oriented to person, place, time, and situation
    Speech was mildly slow and a few mild articulatory errors were noted near the end of the day, and a few paraphasic errors were noted on a confrontation naming task
    There were otherwise no apparent problems with expression and speech was normal with respect to rhythm and prosody
    She was somewhat quiet, but quite cooperative throughout the day
    Affect appeared to be mildly constricted, but she smiled and joked appropriately at times
    Mood appeared to be euthymic
    Receptive language abilities appeared to be within broad normal limits as she was able to appropriately respond to the examiners questions and instructions
    Thought processes were linear and goal-directed and no thought disturbances were noted
    She appeared quite tired near the end of the day and it should be noted that sustained attention task was the last test that was given at the end of the day
    When asked, the patient said that she recognized some of the tests that were given, but not most of them
    She denied having look up any information on any of the tests that are typically involved in a neuropsychological battery or having any additional information about them since her last testing
    Overall, The patient appeared to put full effort into this evaluation
    She was highly motivated and thus these results should be seen as an accurate reflection of her current cognitive functioning.
    Note that there was some overlap in tests that were administered during this evaluation and those given at her evaluation in August of 2009
    These include the ways for CPT II, verbal fluency, and Boston Naming Test, but this exception of the ways for most of these tests are not expected to have a significant practice effect associated with them; however, they will be interpreted with her recent evaluation in mind.
    EVALUATION FINDINGS-
    Classification Level |--| Percentile Rank |--| Standard Score |--| Scaled Score |--| T-Score
    Very Superior |-----| &gt; 98 |-----| &gt;130 |-----| &gt; 16 |-----| &gt; 70
    Superior  |-----| 91-97 |-----| 120-129 |-----| 14-15 |-----| 64-69
    High Average  |-----| 75-90 |-----| 110-119 |-----| 12-13 |-----| 57-63
    Average  |-----| 25-74 |-----| 90-109 |-----| 8-11 |-----| 44-56
    Low Average  |-----| 9-24 |-----| 80-89 |-----| 6-7 |-----| 37-43
    Borderline  |-----| 2-8 |-----| 68-79 |-----| 4-5 |-----| 29-36
    Impaired  |-----| &lt; 1 |-----| &lt; 67  |-----| 1-3 |-----| &lt; 28
    GENERAL COGNITIVE ABILITY: The patient performed in the high average range on a measure of gross cognitive functioning
    Her performances were within expected limits across subtests assessing attention, initiation, visuospatial construction, conceptualization, and memory.
    INTELLECTUAL FUNCTIONING: Based on a word reading test, premorbid intellectual functioning was estimated to fall in the average range
    Current intellectual functioning fell in the average range (Full Scale IQ equals 98, 45th percentile)
    Her index scores also indicated average performance across tasks assessing the verbal knowledge and comprehension, visuospatial perception and reasoning, working memory (i.e
    complex attention) and information processing
    Significant relative strengths were seen on tasks assessing stored verbal knowledge and nonverbal reasoning
    Significant relative weaknesses were seen on task assessing visuospatial perception and construction that included a time demand (low average)
    Compared to her previous test results, the pattern of findings is quite similar with stronger performance on verbal relative to nonverbal tasks or those with significant working memory or speed demands
    However, she performed somewhat better on most of these tasks in the current evaluation
    To some degree this likely reflects measurement error and variability in addition to some practice effects, although some tests are less susceptible to such changes relative to others.
    ACADEMIC ACHIEVEMENT: The patient performed within the average range across tasks assessing word reading, sentence comprehension, and spelling skills
    Her previous evaluation referred to a number of misspellings that she had made on her list of medications and certain medical conditions and she made consistent errors again during this evaluation suggesting that she may simply have a weakness in this area.
    ATTENTION: Auditory working memory was within the average range
    Visual working memory was low average
    On a selective visual attention test, she demonstrated good accuracy in finding target stimuli while scanning information, although her overall speed was in the low average range
    On a continuous performance test assessing sustained attention, she also demonstrated good accuracy, although it was noted that her response time tended to be slower when stimuli were presented with a longer interstimulus interval suggesting some difficulty adapting to changes in the temporal stimulus presentation
    Note that this latter performance is entirely consistent with her previous results and taken together these results suggest that attentional abilities were generally within expectations with some mildly weaker performance for complex visual relative to verbal information
    There were inconsistencies noted in attentional abilities for both verbal and visual working memory.
    INFORMATION PROCESSING SPEED: As noted, the patient had some difficulty adapting to changes in stimulus presentation speed on sustained attention task and there was some suggestion in her performances that she reduced her speed in order to increase accuracy; however, basic visual scanning and sequencing and psychomotor processing were in the average to high average range.
    LANGUAGE: Mild articulatory and paraphasic errors were noted on a confrontation naming test
    Verbal retrieval for phonemic or category cues were in the high average and average range respectively
    Stored verbal knowledge was in the average range with as mentioned strengths noted in vocabulary
    Those language abilities were generally within expected limits.
    VISUOSPATIAL PERCEPTION AND CONSTRUCTION: The patient demonstrated variability in her performances on tasks assessing visuospatial perception and construction
    Basic visual perception assessed through her ability to accurately interpret angular line orientations was in the high average
    Mental organization and synthesis of parts of visual figures was in the average range on an untimed task that involved namable objects and in the low average range and relative weakness when it involved timed synthesis of shapes.
    Visuospatial construction using either line drawings or blocks to match to a sample design was in the borderline to low average range
    Thus taken together, the patient demonstrated some relative weaknesses in aspects of visuospatial processing, particularly when tasks involved the time component or shapes and figures rather than recognizable objects.
    LEARNING & MEMORY SKILLS-
    VERBAL LEARNING AND MEMORY: Initial acquisition of a list of 15 unrelated items within the high average range (she successfully recalled eight items)
    She benefited significantly from repetition and demonstrated immediate and significant improvement in her recall, although across five learning trials, her learning curve was mildly inconsistent suggesting attentional weaknesses; however, following the fifth trial, her overall learning fell in the superior range (all 15 items were successfully recalled)
    Spontaneous recall following a 20-minute delay was in the average range as she was able to remember 11 of the 15 items
    She correctly recognized all those items during recognition (high average)
    Taking together these memory results are well within expected limits
    She demonstrated some mild forgetting, but still fell within the average range and was able to recognize items effectively suggesting mild weaknesses and inconsistencies with retrieval and attentional weaknesses may have contributed to variability in her learning curve initially.
    VISUAL LEARNING AND MEMORY: The patient immediate recall of a complex figure was in the average range
    She retained all of that information following a delay and her recall was in the high average range
    Recognition of the individual components of the figure was impaired
    Taken together these results highlight some difficulties with complex visuospatial perception and construction, although memory per se was intact based on her very strong recall performances
    Note also that she demonstrated this strong memory despite some initial errors in her copy of the complex figure itself again highlighting difficulties with perception and construction, but good overall memory functioning.
    EXECUTIVE FUNCTIONING: As mentioned, working memory was stronger for verbal than visual information, but both were within broad normal limits
    Verbal fluency was within expected limits as well and she demonstrated particular strength in switching between semantic categories (very superior)
    Design fluency was average
    Cognitive set shifting, inhibition of prepotent responses and planning and problem solving all fell within the average to high average range and within expected limits
    Abstract reasoning for visual and verbal information was also good
    Thus taken together, the patient did not demonstrate any difficulties with tasks assessing executive functioning.
    MOTOR FUNCTIONING: Fine motor coordination skills were within the average range with both her right dominant hand and left hand
    Strength was impaired on both sides while basic motor speed was in the borderline range for both hands
    These results are very similar to those that were found in her previous evaluation
    They are consistent with decreased strength, but no loss of dexterity and they do not suggest any lateralized deficits.
    EMOTIONAL FUNCTIONING: On an affective screening measure, the patient endorsed only three items suggesting reduced energy and reduced clarity of thinking
    Overall, these results suggest that she is not experiencing clinically significant depressive symptoms at this time.
    SUMMARY & IMPRESSIONS: This is a 58-year-old African-American female with 16 years of education who is referred for a neuropsychological evaluation by Dr
    X in order to assess for possible cognitive impairment
    She reported that she had recently been terminated from her position as a supervisory nurse in a psychiatric hospital following a neuropsychological evaluation that concluded that she may be in the &quot;early stages of a likely dementia&quot;.
    This evaluation was requested as a second opinion in order to clarify the nature of her cognitive impairment and make recommendations for ongoing treatment planning.
    Overall, intellectual functioning fell in the average range on this evaluation
    Note that the same battery of tests was used in order to assess intellectual functioning and given the recency of her previous evaluation to some degree practice effects may have impacted her performance
    However, we would not expect as significant of an impact upon tasks assessing processing speed and working memory
    The same pattern of performance was noted across subtests in the intellectual battery with stronger performance on verbal knowledge based tasks relative to those assessing processing speed, working memory, and visuospatial skills
    However, during this evaluation, she demonstrated significant improvement across tasks so that in general her profile is elevated, but the same profile pattern was seen
    Note that along with practice effects, performance can also be impacted by changes in attention, as well as measurement error
    The possibility of attentional fluctuations having contributed to some of her performance is a definite possibility based on her performance on some attention tasks, which suggested that she at times performed better and was able to sequence in mind more information than she was able to passively repeat and she demonstrated inconsistencies in her learning curve on the memory task
    Our assessment of her intelligence as falling within the average range is consistent with our estimate of premorbid intellectual functioning and educational and occupational history
    Note that the ways for technical manual indicates that test retest gains are less pronounced for verbal comprehension and working memory subtests than perceptual reasoning and processing speed subtests and The patient improvements were much higher than the mean increases in the normative sample that averaged a shorter period between testing and retesting
    This suggests that as noted above other factors were applied, perhaps including attentional fluctuations
    Relative to intellectual functioning, the patient performed within expected limits across tasks assessing language functioning, verbal and visual memory, and executive skills and sustained attention
    Variability in performances was seen for information processing speed, particularly when responding to changes in the tempo of stimulus presentation or making more complex visual discriminations
    Immediate attention was also somewhat variable as she should be able to mentally manipulate greater amounts of visual and verbal material and she could just passively replicate
    The better performance on raw scores on these more complex tasks is unusual and suggests fluctuations in attention for reasons other than brain base deficits
    She also demonstrated weaker skills in complex visuospatial processing, particularly for shapes and figures rather than recognizable objects
    Of note, she performed poorly when these tasks involved the time component
    Meteorically, she demonstrated bilateral impairments in strength and motor speed, but dexterity and fine motor manipulation skills were within expected limits
    The lack of lateralizing information and motor skills suggests that more peripheral factors and pain may have contributed to these results
    Taken together, these results do not necessarily suggest that there has been any significant decline from premorbid levels
    It is possible that the patient has always had weaknesses in visuospatial processing and note that these weaknesses were not in basic visual skills and processing skills, but rather in putting together shapes and figures
    The inconsistencies in her performances on processing speed and attention tasks suggest that while she may be somewhat slowed in completing tasks, there may be contributors other than brain base changes that impacted her results
    In particular, the patient current medications, as well as pain, fatigue, and medical conditions such as diabetes and associated possible neuropathy are likely important factors.
    Results do not suggest the presence of a progressive dementing disorder
    Given the multiple factors that may have contributed to some of our findings, we do not believe that any cognitive disorder should be diagnosed at this time
    If there are significant concerns about her cognitive functioning, it may be useful to make adjustments in her medication regimen
    This is a not a work based evaluation and therefore questions regarding her ability to perform job duties will not be addressed
    One concern that we would like to bring up is that if visuospatial problems are of newer onset, they may be contributing to her recent falls and thus should be monitored for changes and progression and she should return for reevaluation in one years time in order to determine any changes in her cognitive functioning
    Additional recommendations are provided below.
    Note that there was a little overlap in tests given between the two testing sessions aside from the ways for CPT to word fluency
    Also note that she completed the brief self-report instrument and all of her results fell within normal limits.
    RECOMMENDATIONS-
    Based on fluctuations in processing speed and attention, it is possible that the patient current medications may be impacting her cognitive functioning to some degree
    Decisions regarding any changes are deferred to her physicians, but it may be useful to streamline her current medications while also continuing to effectively address symptoms.
    The patient should return for reevaluation in one years time to monitor her cognitive functioning and evaluate for any improvements in performance following any medication changes
    Note also that stress related to her work situation and the nature of her previous neuropsychological evaluation may have also contributed to some of her difficulties on that testing.
    The nature of her frequent falls is uncertain, but it is possible that weaknesses in visuospatial processing may be contributing and this should be further evaluated by her physicians.
    `,

};

export function ResultSet() {
  const dispatch = useAppDispatch();
  const user = useTypedSelector(selectUser);
  const [actviveTab, setActviveTab] = useState(0);
  const [textVal, setTextVal] = useState("");
  const [textValhcp, setTextValhcp] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const [resultCI, setResultCI] = useState<Array<Iannotation>>([]);
  const [selALlChkCI, setSelALlChkCI] = useState<boolean>(false);
  // const [resultChkCI, setResultChkCI] = useState<Array<boolean>>([]);
  const [marksCI, setMarksCI] = useState<
    Record<string, Array<IannotationDetail>>
  >({});

  const [resultMS, setResultMS] = useState<Array<Iannotation>>([]);
  const [selALlChkMS, setSelALlChkMS] = useState<boolean>(false);
  const [resultChkMS, setResultChkMS] = useState<Array<boolean>>([]);
  const [marksMS, setMarksMS] = useState<
    Record<string, Array<IannotationDetail>>
  >({});
  const [textLen, setTexLen] = useState<number>(0);

  const markerRef = useRef<Mark>();
  useEffect(() => {
    if (!markerRef.current) markerRef.current = new Mark("#hardtext");
    markerRef.current.unmark();
    if (actviveTab === 0) {
      Object.values(marksCI).forEach((e) =>
        e.forEach((a) =>
          markerRef.current!.markRanges(
            [{ start: a.span.begin, length: a.span.end - a.span.begin }],
            {
              className: a.polarity
                ? "negated"
                : a.historyOf
                ? "historyOf"
                : "",
            }
          )
        )
      );
    } else if (actviveTab === 2) {
      Object.values(marksMS).forEach((e) =>
        e.forEach((a) =>
          markerRef.current!.markRanges(
            [{ start: a.span.begin, length: a.span.end - a.span.begin }],
            {
              className: a.polarity
                ? "negated"
                : a.historyOf
                ? "historyOf"
                : "",
            }
          )
        )
      );
    }
  }, [marksCI, marksMS, actviveTab]);

  const markupAllCI = (checked: boolean) => {
    if (checked)
      resultCI.map((r) => {
        setMarksCI((prevState) => ({
          ...prevState,
          [r.uuid]: r.annotationDetails,
        }));
      });
    else setMarksCI({});
  };

  const markupCI = (
    annotation: Array<IannotationDetail>,
    checked: boolean,
    uuid: string,
    index: number
  ) => {
    setResultCI((a) => [
      ...a.slice(0, index),
      { ...a[index], checked: !a[index].checked },
      ...a.slice(index + 1),
    ]);
    if (checked) {
      setMarksCI((prevState) => ({
        ...prevState,
        [uuid]: annotation,
      }));
    } else {
      setMarksCI((prevState) => {
        const thingy = { ...prevState };
        delete thingy[uuid];
        return thingy;
      });
    }
  };

  const markupAllMS = (checked: boolean) => {
    if (checked)
      resultMS.map((r) => {
        setMarksMS((prevState) => ({
          ...prevState,
          [r.uuid]: r.annotationDetails,
        }));
      });
    else setMarksMS({});
  };

  const markupMS = (
    annotation: Array<IannotationDetail>,
    checked: boolean,
    uuid: string
  ) => {
    if (checked) {
      setMarksMS((prevState) => ({
        ...prevState,
        [uuid]: annotation,
      }));
    } else {
      setMarksMS((prevState) => {
        const thingy = { ...prevState };
        delete thingy[uuid];
        return thingy;
      });
    }
  };

  const clickHandler = () => {
    setShowSpinner(true);
    setShowResult(false);
    setMarksCI({}); // resets mark state
    setMarksMS({}); // resets mark state
    setTextValhcp("");
    setSelALlChkCI(false);
    markerRef.current!.unmark();
    axios
      .post<Array<Iannotation>>(`/api/v1/annotations/`, {payload:textVal})
      .then((response) => {
        // setResultChkCI(new Array(response.data.length).fill(false));
        setResultCI(
          response.data.map((e) => ({
            ...e,
            negs: e.annotationDetails.filter((s) => s.polarity).length,
            hist: e.annotationDetails.filter((s) => s.historyOf).length,
            uuid: uuidv4(),
            checked: false,
          }))
        );

        setTextValhcp(textVal);
        setShowResult(true);
        setShowSpinner(false);
      })
      .catch((err) => {
        setShowSpinner(false);
        console.log(err);
      });
  };
  const textformatter = (s: string) => {
    return s.replace(/[^\x00-\xFF]/, "");
  };
  const pastehandler = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    //console.log(event.clipboardData.getData("text"));
    // Transform the copied/cut text to upper case
    const val = textformatter(event.clipboardData.getData("text"));
    event.currentTarget.value = val;
    setTextVal(val);
    setTexLen(val.length);

    event.preventDefault();
  };
  const setSample = (val:string) =>{
    setTexLen(val.length);
    setTextVal(val);
  }
  
  return (
    <Stack>
      <StackItem>
        <TopBar user={user} onLogout={() => dispatch(userActions.logoutUser())}>
          <></>
        </TopBar>
      </StackItem>
      <StackItem>
        <Stack horizontal styles={stackStyles} tokens={stackTokens}>
          <Stack verticalFill styles={stackItemStyles}>
            <Stack horizontal>
              <Label style={{ flex: 1, marginTop: 0, fontSize: 20 }}>
                Unstructured Text Input{" "}
              </Label>
              <Label style={{ flex: 1, marginTop: 13, fontSize: 9 }}>
                {textLen} characters
              </Label>
              <Stack horizontal verticalAlign="center">
                <DefaultButton
                  styles={{ root: { marginRight: 10 } }}
                  onClick={() => setSample(sampleMapper["sample1"])}
                >
                  Sample 1
                </DefaultButton>
                <DefaultButton
                  styles={{ root: { marginRight: 10 } }}
                  onClick={() => setSample(sampleMapper["sample2"])}
                >
                  Sample 2
                </DefaultButton>

                <DefaultButton
                  styles={{ root: { marginRight: 10 } }}
                  onClick={() => setSample(sampleMapper["sample3"])}
                >
                  Sample 3
                </DefaultButton>
                
                <DefaultButton
                  onClick={() => setSample(sampleMapper["sample4"])}
                >
                  Sample 4
                </DefaultButton>
              </Stack>
            </Stack>
            <textarea
              style={{ width: "100%", height: "100%", minHeight: "200px" }}
              className="resize border rounded-md"
              value={textVal}
              onChange={(e) => {
                setTexLen(e.target.value.length);
                setTextVal(e.target.value);
              }}
              onPaste={pastehandler}
            ></textarea>
            <DefaultButton
              style={{ flex: 1, minHeight: "35px" }}
              text="Annotate text"
              onClick={clickHandler}
            >
              Annotate Text
            </DefaultButton>
            <div id="hardtext">{showResult ? textValhcp : ""}</div>
          </Stack>
          <Stack.Item
            grow
            styles={{ ...stackItemStyles }}
            style={{ marginLeft: 15 }}
          >
            {showSpinner ? (
              <AppSpinner />
            ) : (
              /* <div className="flex flex-col justify-center items-center">
                <RingLoader
                  style={{ width: "100%", height: 400 }}
                  size={100}
                  color="lightblue"
                  loading={showSpinner}
                />
              </div>
              */
              <div>
                <Tabs
                  defaultIndex={0}
                  onSelect={(index) => setActviveTab(index)}
                >
                  <TabList>
                    <Tab>Annotations</Tab>
                    <Tab>SNOMED</Tab>
                    {/*<Tab>MSHealth</Tab>*/}
                  </TabList>

                  <TabPanel>
                    {showResult ? (
                      <Annotationlist
                        result={resultCI}
                        markupAll={markupAllCI}
                        markupOne={markupCI}
                        // resultChk={resultChkCI}
                        // setResultChk={setResultChkCI}
                        selALlChk={selALlChkCI}
                        setSelALlChk={(checked: boolean) => {
                          setResultCI((a) => a.map((b) => ({ ...b, checked })));
                          setSelALlChkCI(checked);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {showResult ? <SnomedTable result={resultCI} /> : <></>}
                  </TabPanel>
                </Tabs>
              </div>
            )}
          </Stack.Item>
        </Stack>
      </StackItem>
    </Stack>
  );
}
