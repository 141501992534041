import React from "react";
import { ITheme } from "@fluentui/react";

import Captcha from "react-numeric-captcha";
import "./re-captcha.css";

/*
const captchaStyles = (theme: ITheme) => ({
  rnc: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 255,
    backgroundColor: "#eee",
    borderRadius: 6,
    padding: 10,
    boxSizing: "border-box",
  },

  "rnc-row": {
    display: "flex",
    alignItems: "stretch",
    margin: "0 0 10px 0",
  },

  "rnc-column": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "0 0 0 10px",
  },

  "rnc-canvas": {
    boxSizing: "border-box",
    backgroundColor: "#fff",
    borderRadius: 4,
  },

  "rnc-button": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    background: "#fff",
    color: "inherit",
    border: "none",
    padding: 0,
    width: 25,
    height: 20,
    boxSizing: "border-box",
    borderRadius: 4,
  },

  "rnc-button svg": {
    width: "1em",
    height: "1em",
    fill: "currentColor",
  },

  "rnc-input": {
    border: "none",
    padding: "10px 6px",
    fontSize: "inherit",
    fontFamily: "inherit",
  },
});
*/

export function ReCaptcha({
  theme,
  onChange,
}: {
  theme: ITheme;
  onChange: (status: boolean) => void;
}) {
  return <Captcha onChange={onChange} />;
}
