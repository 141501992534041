// https://github.com/microsoft/fluentui
// https://developer.microsoft.com/en-us/fluentui#/controls/web
// yarn add @fluentui/react
import React, { useEffect, Suspense } from "react";
import { LoginPage, ForgotPassword, ResetPassword } from "./components/Auth";

import { PrivateRoute } from "./utils";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import "office-ui-fabric-react/dist/css/fabric.css";
import "./App.css";

import { FluedThemedContainer, ThemeProvider } from "./utils/hooks/use-theme";
import { MessageBarProvider } from "./components/Notification";
import { useMessage } from "./utils/hooks";
import { AppProvider } from "./utils/context";
import { AppSpinner } from "./components/Basic/AppSpinner";
import { ResultSet } from "./pages/resultset";

function App() {
  const [setMessage] = useMessage();

  useEffect(() => {
    // @ts-ignore
    window.setMessage = setMessage;
  }, [setMessage]);

  return (
    <AppProvider
      value={{
        setMessage,
      }}
    >
      <ThemeProvider>
        <FluedThemedContainer>
          <BrowserRouter>
            <MessageBarProvider>
              <Switch>
                <Route exact path="/login" component={LoginPage} />
                <Route
                  exact
                  path="/forgotpassword"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/resetpassword/:token"
                  component={ResetPassword}
                />
                <PrivateRoute path="/">
                  <Switch>
                    <Route path="/resultset">
                      <Suspense fallback={<AppSpinner />}>
                        <ResultSet />
                      </Suspense>
                    </Route>
                    <Route path="*">
                      <Redirect
                        to={{
                          pathname: `/resultset`,
                          state: "/",
                        }}
                      ></Redirect>
                    </Route>
                  </Switch>
                </PrivateRoute>
              </Switch>
            </MessageBarProvider>
          </BrowserRouter>
        </FluedThemedContainer>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
