import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import rootReducer from "./rootReducer";

export const createStore = (opts?: Partial<ConfigureStoreOptions>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: opts?.preloadedState,
  });

const store = createStore();

export { store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type StoreType = typeof store;
