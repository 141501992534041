
import React from "react";
import { Spinner } from "@fluentui/react";
import RingLoader from "react-spinners/RingLoader";

export function AppSpinner({height = 400}: {height?: number}) {
    return (
        <Spinner
            styles={{
            root: { width: "100%", height },
            circle: { height: 96, width: 96 },
            }}
        /> 
    );
}