import React from "react";
import { mergeStyleSets, ITheme } from "@fluentui/react";
import { useAppTheme } from "../../utils/hooks";
import { AuthHeader } from "./AuthHeader";

const authTemplateStyles = (theme: ITheme) =>
  mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      position: "absolute",
      minWidth: "100%",
      minHeight: "100%",
      justifyContent: "center",
      marginTop: -30,
      top: 0,
      left: 0,
      backgroundColor: theme.palette.neutralLighterAlt,
      fontWeight: 400,
      MozOsxFontSmoothing: "grayscale",
      WebkitFontSmoothing: "antialiased",
      selectors: {
        "@media(max-width: 640px)": {
          backgroundColor: theme?.palette?.white,
          marginTop: 0,
        },
      },
    },

    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      fontSize: 16,
      color: theme.palette?.neutralSecondary,
      selectors: {
        "@media(max-width: 640px)": {
          flexGrow: 0,
          padding: "5px 0",
          color: "white",
          backgroundColor: theme.palette?.themeSecondary,
        },
      },
    },

    body: {
      display: "flex",
      height: 450,
      margin: 16,
      marginTop: 0,
      alignSelf: "center",
      boxShadow: "5px 5px 25px silver",

      selectors: {
        "@media(max-width: 640px)": {
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "center",
          margin: 0,
          width: "100%",
          boxShadow: "none",
          height: "auto",
        },
      },
    },

    footer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "center",
      // flexGrow: 5,
      fontSize: "16px",
      color: theme.palette?.neutralSecondary,

      selectors: {
        "@media(max-width: 640px)": {
          flexGrow: 0,
          color: theme?.palette?.white,
          backgroundColor: theme.palette?.themeSecondary,
          padding: "5px 0",
        },
      },
    },

    column1: {
      flexGrow: 5,
      display: "flex",
      flexDirection: "column",
      padding: "24px 20px",
      boxSizing: "border-box",
      color: theme.palette?.white,
      backgroundColor: theme.palette?.themeSecondary,
      borderRadius: "6px 0 0 6px",
      textAlign: "center",

      backgroundImage:
        `url('data:image/svg+xml;charset=UTF-8,` +
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">` +
        '<g transform="translate(0 15)">' +
        `<circle fill="white" opacity="0.14" cx="0" cy="50" r="30"/>` +
        `<circle fill="white" opacity="0.14" cx="10" cy="80" r="50"/>` +
        `<circle fill="white" opacity="0.14" cx="10" cy="100" r="30"/>` +
        `<circle fill="white" opacity="0.17" cx="70" cy="100" r="65"/>` +
        `<circle fill="white" opacity="0.16" cx="120" cy="60" r="50"/>` +
        "</g>" +
        `</svg>')`,

      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",

      selectors: {
        "@media(max-width: 640px)": {
          color: theme.palette?.black,
          flexGrow: 0,
          margin: "1em 2em",
          padding: 0,
          background: "none",
          borderRadius: 0,
        },
      },
    },

    column2: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "250px",
      maxWidth: "250px",
      padding: "24px 20px",
      flexGrow: 7,
      backgroundColor: theme.palette?.white,
      borderRadius: "0 6px 6px 0",

      selectors: {
        "@media(max-width: 640px)": {
          display: "block",
          minWidth: "300px",
          background: "none",
          borderRadius: 0,
        },
      },
    },

    column1Top: {
      flexGrow: 1,
    },

    headline: {
      fontSize: "30px",
      fontWeight: 200,
      marginTop: "10px",
      height: "40px",
    },

    subheadline: {
      fontSize: "16px",
      fontWeight: 200,
      marginTop: "6px",
    },

    column2Top: {
      flexGrow: 1,
    },

    rememberLogin: {
      margin: "1em 0 0 0",
    },

    defaultIntro: {
      width: "220px",
      maxWidth: "220px",
    },

    loginIcon: {
      selectors: {
        "@media(max-width: 640px)": {
          display: "none",
        },
      },
    },
  });

export function AuthTemplate({
  heading,
  subHeading,
  Icon,
  children,
}: {
  heading: string;
  subHeading: string;
  //   @ts-ignore
  Icon;
  children: React.ReactChild;
}) {
  const { selectedTheme } = useAppTheme();
  const classes = authTemplateStyles(selectedTheme as ITheme);

  return (
    <div className={classes.root}>
      {/* Header */}
      <div className={classes.header}>
        <AuthHeader />
      </div>
      {/* Main Body */}
      <div className={classes.body}>
        {/* Left Body */}
        <div className={classes.column1}>
          <div className={classes.column1Top}>
            <div className={classes.defaultIntro}>
              <div className={classes.headline}>{heading}</div>
              <div className={classes.subheadline}>{subHeading}</div>
            </div>
          </div>
          <div>
            <Icon size="70" className={classes.loginIcon} />
          </div>
        </div>
        {children}
      </div>
      {/* Footer */}
      <div className={classes.footer}>
        &copy; {new Date().getFullYear()}, CareIndexing
      </div>
    </div>
  );
}
