import React from "react";
import { mergeStyleSets } from "@fluentui/react";
import CILogo from "../../assets/cilogo.png";

const headerClasses = mergeStyleSets({
  root: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
    fontSize: "18px",
    userSelect: "none",
    transform: "scale(.95, .95)",
  },

  column1: {
    padding: "0 12px 0 0",
  },

  column2: {
    display: "flex",
    flexDirection: "column",
  },

  logo: {},

  defaultLogo: {
    height: 50,
    padding: "0.7em 0 0 0",
  },

  company: {
    fontSize: "18px",
    margin: "3px 0 -4px 0",
  },

  title: {
    fontSize: "23px",
    marginTop: "-1px",
  },
});

export function AuthHeader() {
  const brandLogo = <img src={CILogo} className={headerClasses.defaultLogo} />;
  const column1 = (
    <div className={headerClasses.column1}>
      <div className={headerClasses.logo}>{brandLogo}</div>
    </div>
  );
  const column2 = (
    <div className={headerClasses.column2}>
      <div className={headerClasses.company}>Care Indexing</div>
      <div className={headerClasses.title}>Back Office</div>
    </div>
  );

  return (
    <div className={`${headerClasses.root}`}>
      {column1}
      {column2}
    </div>
  );
}
