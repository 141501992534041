import React from "react";
import { Iannotation, IannotationDetail } from "../types";
import { List, Checkbox, Stack } from "office-ui-fabric-react";

interface Props {
  result: Array<Iannotation>;
  markupAll: (checked: boolean) => void;
  markupOne: (
    annotation: Array<IannotationDetail>,
    checked: boolean,
    uuid: string,
    index: number
  ) => void;
  // resultChk: Array<boolean>;
  // setResultChk: Dispatch<SetStateAction<Array<boolean>>>;
  selALlChk: boolean;
  setSelALlChk: (checked: boolean) => void;
}

const ChevronRight = () => (
  <svg
    style={{ display: "inline-block" }}
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5l7 7-7 7"
    />
  </svg>
);

export const Annotationlist: React.FC<Props> = ({
  result,
  markupOne,
  markupAll,
  // resultChk,
  // setResultChk,
  selALlChk,
  setSelALlChk,
}) => {
  const selectAll = (checked: boolean) => {
    // setResultChk(new Array(resultChk.length).fill(checked));
    setSelALlChk(checked);
    markupAll(checked);
  };
  const selectOne = (
    annotation: Array<IannotationDetail>,
    checked: boolean,
    uuid: string,
    chkIdx: number
  ) => {
    // resultChk[chkIdx] = checked;setResultChk([...resultChk]);
    markupOne(annotation, checked, uuid, chkIdx);
  };
  const onRenderCell = (
    item: Iannotation | undefined,
    index: number | undefined
  ): JSX.Element => {
    return item ? (
      <Checkbox
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          checked?: boolean
        ) => {
          selectOne(
            item.annotationDetails,
            checked || false,
            item.uuid,
            index as number
          );
        }}
        styles={{
          root: { padding: 2 },
        }}
        checked={item.checked}
        onRenderLabel={() => (
          <Stack
            horizontal
            verticalAlign="center"
            style={{ fontWeight: item.checked ? "bold" : "inherit" }}
          >
            <span className="preftext">
              {item?.preferredTerm}
              {item?.annotationDetails?.length > 1 ? (
                <>({item?.annotationDetails?.length})</>
              ) : (
                <></>
              )}
            </span>
            {item?.negs + item?.hist ? (
              <Stack horizontal verticalAlign="center">
                {item?.negs ? (
                  <Stack horizontal verticalAlign="center">
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: 20,
                      }}
                    >
                      <ChevronRight />
                    </span>
                    <span>Negated: {item?.negs}</span>
                  </Stack>
                ) : (
                  <></>
                )}
                {item?.hist ? (
                  <Stack horizontal verticalAlign="center">
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: 20,
                      }}
                    >
                      <ChevronRight />
                    </span>
                    <span>History of: {item?.hist}</span>
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        )}
      />
    ) : (
      <span></span>
    );
  };

  return (
    <>
      <Checkbox
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          checked?: boolean
        ) => {
          selectAll(checked || false);
        }}
        styles={{
          root: { padding: "10px 5px" },
        }}
        checked={selALlChk}
        label="Select All"
      />
      <hr />
      <List
        items={result}
        style={{ height: "75vh", overflow: "scroll" }}
        onRenderCell={onRenderCell}
      />
    </>
  );
};
