
export const themes = {
    light: {
        palette: {
            themePrimary: '#b2edb6',
            themeLighterAlt: '#9ed3a2',
            themeLighter: '#8bb98e',
            themeLight: '#779f7a',
            themeTertiary: '#648566',
            themeSecondary: '#506b52',
            themeDarkAlt: '#3c513e',
            themeDark: '#29372a',
            themeDarker: '#151c16',
            neutralLighterAlt: '#faf9f8',
            neutralLighter: '#f3f2f1',
            neutralLight: '#edebe9',
            neutralQuaternaryAlt: '#e1dfdd',
            neutralQuaternary: '#d0d0d0',
            neutralTertiaryAlt: '#c8c6c4',
            neutralTertiary: '#a19f9d',
            neutralSecondary: '#605e5c',
            neutralPrimaryAlt: '#3b3a39',
            neutralPrimary: '#323130',
            neutralDark: '#201f1e',
            black: '#000000',
            white: '#ffffff',
        }
    },
    dark: {
        palette: {
            themePrimary: '#b2edb6',
            themeLighterAlt: '#9ed3a2',
            themeLighter: '#8bb98e',
            themeLight: '#779f7a',
            themeTertiary: '#648566',
            themeSecondary: '#506b52',
            themeDarkAlt: '#3c513e',
            themeDark: '#29372a',
            themeDarker: '#151c16',
            neutralLighterAlt: '#0b0b0b',
            neutralLighter: '#151515',
            neutralLight: '#252525',
            neutralQuaternaryAlt: '#2f2f2f',
            neutralQuaternary: '#373737',
            neutralTertiaryAlt: '#595959',
            neutralTertiary: '#c8c8c8',
            neutralSecondary: '#d0d0d0',
            neutralPrimaryAlt: '#dadada',
            neutralPrimary: '#ffffff',
            neutralDark: '#f4f4f4',
            black: '#f8f8f8',
            white: '#000000',
        }
    }
};
