import React, {
  useState,
  createContext,
  ReactChild,
  useEffect,
  useContext,
} from "react";
import { loadTheme, Fabric } from "@fluentui/react";
import { themes } from "./themes";

export type IAppTheme = "light" | "dark";

const getInitialThemeState = (): IAppTheme =>
  (localStorage.getItem("theme") || "light") as IAppTheme;

function useThemeState() {
  const [theme, setTheme] = useState<IAppTheme>(getInitialThemeState());
  function setAndSaveTheme(selectedTheme: IAppTheme) {
    localStorage.setItem("theme", selectedTheme);
    setTheme(selectedTheme);
  }
  return [theme, setAndSaveTheme] as const;
}

const ThemeContext = createContext({});
const ThemeProvider = ({ children }: { children: ReactChild }) => (
  <ThemeContext.Provider value={useThemeState()}>
    {children}
  </ThemeContext.Provider>
);

const useThemeContext = () => useContext(ThemeContext);

const FluedThemedContainer = ({ children }: { children: ReactChild }) => {
  // @ts-ignore
  const [theme] = useThemeContext();
  useEffect(() => {
    loadTheme(themes[theme as IAppTheme]);
  }, [theme]);
  return (
    <Fabric styles={{ root: { minHeight: "100%" } }} applyTheme>
      {" "}
      {children}{" "}
    </Fabric>
  );
};

const useThemeColor = (colorId: string) => {
  const [theme] = useThemeState();
  const {
    // @ts-ignore
    palette: { [colorId]: color },
  } = themes[theme];
  return [color] as const;
};

const ThemeConfig = () => {
  // @ts-ignore
  const [theme, setTheme] = useThemeContext();
  const selectedTheme = themes[theme as IAppTheme];
  return { selectedTheme, selectedThemeName: theme, setTheme };
};

export {
  ThemeConfig,
  useThemeContext,
  ThemeProvider,
  FluedThemedContainer,
  useThemeColor,
};
