import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const userForgotPasswordThunk = createAsyncThunk(
  "users/auth/password_reset/",
  async (email: string, { rejectWithValue }) => {
    try {
      const res = await axios.post("/api/v1/users/auth/password_reset/", {
        email,
      });
      return res.data;
    } catch (error) {
      console.log({ error });
      return rejectWithValue({ message: "Error: Invalid Email" });
    }
  }
);

export const userSetPassword = createAsyncThunk(
  "users/auth/password_reset/confirm/",
  async (
    { password, token }: { password: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        "/api/v1/users/auth/password_reset/confirm/",
        { password, token }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue({
        message: "Error: In setting the password.",
      });
    }
  }
);

export const validateResetToken = createAsyncThunk(
  "users/auth/password_reset/validate_token/",
  async (token: string, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "/api/v1/users/auth/password_reset/validate_token/",
        { token }
      );
      return res.data;
    } catch (error: any) {
      console.log({ error });
      return rejectWithValue({
        statusText: error.response.statusText,
        status: error.response.status,
      });
    }
  }
);

const initialState: PasswordState = { loading: false, error: "", valid: false };

const slice = createSlice({
  name: "password",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateResetToken.pending, (state, action) => {
        state = { ...initialState, loading: true };
      })
      .addCase(validateResetToken.fulfilled, (state, { payload }) => {
        state = { ...initialState, loading: false, valid: true };
        // console.log({ payload });
        // state.details = details;
      })
      .addCase(validateResetToken.rejected, (state, action) => {
        state = {
          error: "Error: Invalid Reset Token.",
          loading: false,
          valid: false,
        };
      })
      .addCase(userSetPassword.pending, (state, action) => {
        state = { ...initialState, loading: true };
      })
      .addCase(userSetPassword.fulfilled, (state, { payload }) => {
        state = { ...initialState, loading: false, valid: true };
        // console.log({ payload });
        // state.details = details;
      })
      .addCase(userSetPassword.rejected, (state, action) => {
        state = {
          error: "Error: Issue in setting the password.",
          loading: false,
          valid: false,
        };
      })
      .addCase(userForgotPasswordThunk.pending, (state, action) => {
        state = { ...initialState, loading: true };
      })
      .addCase(userForgotPasswordThunk.fulfilled, (state, { payload }) => {
        state = { ...initialState, loading: false, valid: true };
        // console.log({ payload });
        // state.details = details;
      })
      .addCase(userForgotPasswordThunk.rejected, (state, action) => {
        console.log({ state });
        state = {
          error: "Error: Issue in setting the password.",
          loading: false,
          valid: false,
        };
      });
  },
});

export default slice.reducer;
export const passwordActions = {
  ...slice.actions,
  userForgotPasswordThunk,
  validateResetToken,
  userSetPassword,
};

export interface PasswordState {
  error: string;
  loading: boolean;
  valid: boolean;
}
