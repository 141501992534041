import React, { useEffect, useState } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import {
  checkSession,
  selectUser,
} from "../features/users/usersSlice";
import { useTypedSelector, useAppDispatch } from "../store";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [resolved, setResolved] = useState(false);
  const dispatch = useAppDispatch();

  const user = useTypedSelector(selectUser);

  useEffect(() => {
    if (!resolved) {
      if (!!!user.id) {
        dispatch(checkSession()).finally(() => {
          setResolved(true);
        });
      } else {
        setResolved(true);
      }
    }
  }, [user, resolved, dispatch]);

  return !resolved ? null : (
    <Route
      {...rest}
      render={({ location }) => {
        if (!!user.id) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
