import { MessageBarNotification } from "../../components/Notification";
import { MessageBarType } from "@fluentui/react";
import { useAppDispatch } from "../../store";
import { appsettingsActions } from "../../features/appsettings/appsettingsSlice";

export function useMessage() {
  const dispatch = useAppDispatch();

  function setMessageBar(message: MessageBarNotification) {
    dispatch(appsettingsActions.setMessage(message));
    let timeoutId = setTimeout(() => {
      dispatch(
        appsettingsActions.setMessage({ text: "", type: MessageBarType.info })
      );
      timeoutId && clearTimeout(timeoutId);
    }, 6000);
  }

  return [setMessageBar] as const;
}
