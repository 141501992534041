import React, { useCallback, useEffect, useContext } from "react";
import * as Yup from "yup";

import {
  PrimaryButton,
  TextField,
  MessageBarType,
  mergeStyleSets,
  ITheme,
} from "@fluentui/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useAppDispatch } from "../../../store"; //RootState
import { userActions } from "../../../features/users/usersSlice";
import { IoIosUnlock as LoginIcon } from "react-icons/io";

import { AuthTemplate } from "../AuthTemplate";
import { useAppTheme } from "../../../utils/hooks";
import { Link as FluentLink } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../utils/context";

const loginScreenStyles = (theme: ITheme) =>
  mergeStyleSets({
    column2: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "250px",
      maxWidth: "250px",
      padding: "24px 20px",
      flexGrow: 7,
      backgroundColor: theme.palette?.white,
      borderRadius: "0 6px 6px 0",

      selectors: {
        "@media(max-width: 640px)": {
          display: "block",
          minWidth: "300px",
          background: "none",
          borderRadius: 0,
        },
      },
    },

    column2Top: {
      flexGrow: 1,
    },

    loginButton: {
      width: "100%",
      margin: "16px 0 0 0 !important",
      zIndex: 32000,
    },

    forgotPassword: {
      color: theme.palette.themeLight,
      float: "right",
    },
  });

interface Props {
  loginState: {
    [key: string]: string;
  };
  onLoginSuccess: () => void;
}
export const LoginForm: React.FC<Props> = ({ onLoginSuccess, loginState }) => {
  const dispatch = useAppDispatch();
  const { setMessage } = useContext(AppContext);

  const { handleSubmit, errors, control, setError } = useForm<LoginFormData>({
    resolver: yupResolver(
      Yup.object().shape({
        username: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required"),
      })
    ),
  });

  const handleLogin = useCallback(
    (values: LoginFormData) =>
      dispatch(
        userActions.userLoginThunk({
          username: values.username,
          password: values.password,
        })
      ).then((action) => {
        if (userActions.userLoginThunk.rejected.match(action)) {
          setError("username", {
            type: "notMatch",
            message: "Wrong username",
          });
          setError("password", {
            type: "notMatch",
            message: "Wrong password",
          });
        } else {
          onLoginSuccess();
        }
      }),
    [dispatch, setError, onLoginSuccess]
  );

  const onSubmit = (values: LoginFormData) => handleLogin(values);

  const { selectedTheme } = useAppTheme();
  const classes = loginScreenStyles(selectedTheme as ITheme);
  const history = useHistory();

  const { isInvalidLink } = loginState || {};
  useEffect(() => {
    if (Boolean(isInvalidLink)) {
      setMessage({
        text: "Invalid Link, Please generate another link.",
        type: MessageBarType.error,
      });
    }
  }, [isInvalidLink, setMessage]);

  return (
    <AuthTemplate
      heading={"Login"}
      subHeading={"Please enter your personal credentials to log in."}
      Icon={LoginIcon}
    >
      <>
        {/* Right Body */}
        <form
          className={classes.column2}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className={classes.column2Top}>
            <Controller
              as={TextField}
              errorMessage={errors.username?.message}
              label="Username"
              name="username"
              control={control}
              defaultValue=""
              required
            />
            <Controller
              as={TextField}
              type="password"
              errorMessage={errors.password?.message}
              label="Password"
              name="password"
              control={control}
              defaultValue=""
              required
            />
          </div>
          <div>
            {/* <div className={classes.rememberLogin}>
              <Checkbox name="rememberLogin" label={"Remember Login"} />
            </div> */}
            <PrimaryButton type="submit" className={classes.loginButton}>
              LogIn
            </PrimaryButton>
            {/*<FluentLink
              className={classes.forgotPassword}
              onClick={() => history.push("/forgotpassword")}
            >
              Forgot Password
            </FluentLink>
            */}
          </div>
        </form>
      </>
    </AuthTemplate>
  );
};

export type LoginFormData = {
  username: string;
  password: string;
};
