import React, { useCallback, useState, useEffect, useContext } from "react";
import * as Yup from "yup";

import {
  PrimaryButton,
  TextField,
  mergeStyleSets,
  ITheme,
  Stack,
  Spinner,
  MessageBarType,
} from "@fluentui/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useAppDispatch } from "../../../store"; //RootState
import { passwordActions } from "../../../features/password/passwordSlice";
import { IoIosLock as PasswordIcon } from "react-icons/io";
import { useParams, Redirect } from "react-router-dom";

import { useAppTheme } from "../../../utils/hooks";
import { AuthTemplate } from "../AuthTemplate";
import { AppContext } from "../../../utils/context";

const resetPasswordStyles = (theme: ITheme) =>
  mergeStyleSets({
    column2: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "250px",
      maxWidth: "250px",
      padding: "24px 20px",
      flexGrow: 7,
      backgroundColor: theme.palette?.white,
      borderRadius: "0 6px 6px 0",

      selectors: {
        "@media(max-width: 640px)": {
          display: "block",
          minWidth: "300px",
          background: "none",
          borderRadius: 0,
        },
      },
    },

    column2Top: {
      flexGrow: 1,
    },

    resetPasswordButton: {
      width: "100%",
      margin: "16px 0 0 0 !important",
      zIndex: 32000,
    },
  });

interface Props {}
export const ResetPasswordForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [resetPasswordState, setResetPasswordState] = useState<string>(
    "validating"
  );
  // @ts-ignore
  const { token } = useParams();
  const { setMessage } = useContext(AppContext);

  const { handleSubmit, errors, control } = useForm<IResetPassword>({
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .required("Password is required")
          .matches(
            /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,
            "Password needs to be in alphanumeric Format."
          )
          .min(8, "Password must be at least 8 chars.")
          .max(16, "At most 16 chars. are are allowed."),

        confirmPassword: Yup.string()
          .required("Password is required")
          .oneOf([Yup.ref("password")], "Passwords must match"),
      })
    ),
  });

  useEffect(() => {
    dispatch(passwordActions.validateResetToken(token)).then((result) => {
      // @ts-ignore
      const { error, payload } = result;
      if (error && error.message) {
        setResetPasswordState("invalid");
      } else if (payload) {
        setResetPasswordState("valid");
      }
    });
  }, [token, dispatch]);

  const handleLogin = useCallback(
    (passwordForm: IResetPassword) => {
      const { password } = passwordForm;
      dispatch(passwordActions.userSetPassword({ password, token })).then(
        (action) => {
          if (passwordActions.userSetPassword.rejected.match(action)) {
            const {
              // @ts-ignore
              payload: { message },
            } = action;
            setMessage({
              type: MessageBarType.error,
              text: message || "Error: Please set valid Password.",
            });
          } else {
            setResetPasswordState("completed");
          }
        }
      );
    },
    [dispatch, setMessage, token]
  );

  const onSubmit = (passwordForm: IResetPassword) => handleLogin(passwordForm);

  const { selectedTheme } = useAppTheme();
  const classes = resetPasswordStyles(selectedTheme as ITheme);

  if (resetPasswordState === "completed") {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: "/resetPassword" },
        }}
      />
    );
  }

  if (resetPasswordState === "validating") {
    return (
      <Stack
        styles={{ root: { height: "80vh" } }}
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
      >
        <Spinner styles={{ circle: { height: 96, width: 96 } }} />
      </Stack>
    );
  }

  if (resetPasswordState === "invalid") {
    console.log("Error Invalid Link, Please Generate another link");
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: "/resetPassword", isInvalidLink: true },
        }}
      />
    );
  }

  return (
    <AuthTemplate
      heading={"Reset Password"}
      subHeading={"Please reset your password."}
      Icon={PasswordIcon}
    >
      <>
        {/* Right Body */}
        <form
          className={classes.column2}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className={classes.column2Top}>
            <Controller
              as={TextField}
              errorMessage={errors.password?.message}
              label="Password"
              name="password"
              control={control}
              type="password"
              defaultValue=""
              required
            />
            <Controller
              as={TextField}
              errorMessage={errors.confirmPassword?.message}
              label="Confirm Password"
              name="confirmPassword"
              control={control}
              defaultValue=""
              required
            />
          </div>
          <div>
            <PrimaryButton
              type="submit"
              className={classes.resetPasswordButton}
            >
              Set Password
            </PrimaryButton>
          </div>
        </form>
      </>
    </AuthTemplate>
  );
};

export type IResetPassword = {
  password: string;
  confirmPassword: string;
};
