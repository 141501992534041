import { MessageBarType } from "@fluentui/react";
import { MessageBarNotification } from "../../components/Notification";

const initialState = {
  message: {
    text: "",
    type: MessageBarType.info,
  },
  locale: "en-GB"
};

const setMessage = (message: MessageBarNotification) => ({
  type: "SET_MESSAGE",
  body: message,
});

const setLocale = ( locale: string ) => ({
  type: "SET_LOCALE",
  body: locale
});

const appsettingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_MESSAGE":
      return {...state, message: action.body};
    case "SET_LOCALE":
      return {...state, locale: action.body}
    default:
      return state;
  }
};
export default appsettingsReducer;

export const appsettingsActions = {
  setMessage,
  setLocale
};
