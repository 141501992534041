import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import _ from "lodash";
export const fetchTermThunk = createAsyncThunk(
  "fetch/term",
  async ({
    term = "snomed",
    page = "1",
    filterDone = true,
    filterText = "",
  }: {
    term: string;
    page: string;
    filterDone: boolean;
    filterText: string;
  }) => {
    const elm = (v: string | number | undefined, n: string, ok: boolean) => ({
      v,
      n,
      ok,
    });
    const params = [
      elm(page, "page", page.trim().length > 0),
      elm(term, "term", term.length > 0),
      elm(+filterDone, "filter_done", filterDone),
      elm(filterText, "term_has", filterText != ""),
    ];
    const qs = params
      .filter((p) => p.ok)
      .map((e) => `${e.n}=${e.v}`)
      .join("&");
    const url = `/api/v1/terms/concepts/?${qs}`;

    const response = await axios.get<{
      first: string;
      last: string;
      count: number;
      total_pages: number;
      current: number;
      next: string;
      previous: string;
      results: Record<number, IType>;
    }>(url);
    return response.data;
  }
);

export const fetchSynonymThunk = createAsyncThunk(
  "fetch/synonym",
  async ({ conceptId }: { conceptId: string }, { rejectWithValue }) => {
    const response = await axios.get(
      `/api/v1/terms/synonyms/?concept=${conceptId}`
    );
    return response.data;
  }
);

export const updateSynonymStatus = createAsyncThunk(
  "update/synonymStatus",
  async ({
    userId,
    termId,
    status,
  }: {
    userId: number;
    termId: string;
    status: IAcceptStatus;
  }) => {
    const response = await axios.patch(`/api/v1/terms/synonyms/${termId}/`, {
      acceptstatus: status,
      userId,
      date: new Date().toISOString(),
    });
    return response.data;
  }
);

export const updateConceptTodoCount = createAsyncThunk(
  "update/termtodo",
  async ({ id, count }: { id: number; count: number }) => {
    const response = await axios.patch(`/api/v1/terms/concepts/${id}/`, {
      unprocessed_synonyms: count,
    });
    return response.data;
  }
);

export const addSynonymThunk = createAsyncThunk(
  "term/addSynonym",
  async ({ term, concept }: { term: string; concept: number }) => {
    const response = await axios.post(`/api/v1/terms/synonyms/`, {
      term,
      acceptstatus: 1,
      concept,
    });
    return response.data;
  }
);

const initialState = {
  first: "-1",
  last: "-1",
  count: -1,
  total_pages: -1,
  current: -1,
  next: "-1",
  previous: "-1",
  results: {} as Record<number, IType>,
  synonyms: {} as Record<number, ISynonym>,
  selectedTerm: -1,
  isTypeLoading: false,
  isSynonymLoading: false,
} as ITermState;

const slice = createSlice({
  name: "term",
  initialState: initialState,
  reducers: {
    setSelectedTerm: (state, action: PayloadAction<number>) => {
      state.selectedTerm = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchTermThunk.pending.toString()]: (state, action) => {
      return { ...state, results: {}, synonyms: {}, isTypeLoading: true };
    },
    [fetchTermThunk.fulfilled.toString()]: (state, { payload }) => {
      const allKeys = Object.keys(payload.results || {});
      if (allKeys.length) {
        return { ...payload, selectedTerm: +allKeys[0], isTypeLoading: false };
      }
      return payload;
    },
    [fetchTermThunk.rejected.toString()]: (state, action) => {
      return { ...initialState, isTypeLoading: true };
    },

    [fetchSynonymThunk.pending.toString()]: (state, action) => {
      state.isSynonymLoading = true;
    },
    [fetchSynonymThunk.fulfilled.toString()]: (state, { payload, meta }) => {
      state.synonyms = payload || {};
      state.isSynonymLoading = false;
    },
    [fetchSynonymThunk.rejected.toString()]: (state, action) => {
      state.isSynonymLoading = false;
    },

    [updateSynonymStatus.pending.toString()]: (state, action) => {
      return state;
    },
    [updateSynonymStatus.fulfilled.toString()]: (state, { payload, meta }) => {
      const { id } = payload;
      if (id) {
        state.synonyms[id] = payload;
      }
    },
    [updateSynonymStatus.rejected.toString()]: (state, action) => {
      return state;
    },

    [updateConceptTodoCount.pending.toString()]: (state, action) => {
      return state;
    },
    [updateConceptTodoCount.fulfilled.toString()]: (
      state,
      { payload, meta }
    ) => {
      const { id } = payload;
      if (id) {
        state.results[id] = payload;
      }
    },
    [updateConceptTodoCount.rejected.toString()]: (state, action) => {
      return state;
    },

    [addSynonymThunk.pending.toString()]: (state, action) => {
      return state;
    },
    [addSynonymThunk.fulfilled.toString()]: (state, { payload, meta }) => {
      const { id } = payload;
      if (id) state.synonyms[id] = payload;
    },
    [addSynonymThunk.rejected.toString()]: (state, action) => {
      return state;
    },
  },
});

export default slice.reducer;
export const termActions = {
  ...slice.actions,
  fetchTermThunk,
  fetchSynonymThunk,
  updateSynonymStatus,
  updateConceptTodoCount,
  addSynonymThunk,
};

export const selectTerms = (state: RootState): Record<number, IType> =>
  state.term?.results;

export const selectedTerm = (state: RootState) => state.term.selectedTerm;

export const selectSynonyms = (state: RootState) =>
  _.sortBy( Object.values(state.term?.synonyms || {}), 'term' );

export const selectSynonymMap = (state: RootState) => state.term?.synonyms;

export const selectPagination = (state: RootState) => state.term;
export const isSynonymLoading = (state: RootState) =>
  state.term.isSynonymLoading;
export const isTypeLoading = (state: RootState) => state.term.isTypeLoading;

type ITermState = {
  first: string;
  last: string;
  count: number;
  total_pages: number;
  current: number;
  next: string;
  previous: string;
  results: Record<number, IType>;
  synonyms: Record<number, ISynonym>;
  selectedTerm: number;
  isTypeLoading: boolean;
  isSynonymLoading: boolean;
};

export interface IType {
  id: number;
  codesystem: string;
  code: string;
  term: string;
  category: string;
  total_synonyms: number;
  synonyms: number[];
  unprocessed_synonyms: number;
}

export type ISynonymType = "wiki" | "md" | "snomed" | "custom";

export enum IAcceptStatus {
  "rejected" = 0,
  "accepted",
  "nothing chosen",
  "doubt",
}

export interface ISynonym {
  id: number;
  engine: string;
  term: string;
  concept: number;
  acceptstatus: number;
  processed_by?: any;
  approve_dt: string;
}
