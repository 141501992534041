import React, { useEffect, useState } from "react";
import { MessageBarNotification } from "./MessageBarNotification";
import { MessageBar } from "@fluentui/react/lib/MessageBar";

interface Props {
  message: MessageBarNotification;
}

const NotificationMessageBar: React.FC<Props> = (props) => {
  const { message } = props;

  const [isDisplay, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (message.text && message.text.length > 0) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [message]);

  return isDisplay ? (
    <MessageBar
      styles={{ root: { position: "relative", maxWidth: "20vw" } }}
      onDismiss={() => setDisplay(false)}
      dismissButtonAriaLabel="Close"
      messageBarType={message.type}
    >
      {message.text}
    </MessageBar>
  ) : null;
};

export default NotificationMessageBar;
