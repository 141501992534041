import React, { useCallback, useState, useEffect, useContext } from "react";
import * as Yup from "yup";

import {
  PrimaryButton,
  TextField,
  mergeStyleSets,
  ITheme,
  MessageBarType,
  Label,
  Text,
} from "@fluentui/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useAppDispatch } from "../../../store"; //RootState
import { passwordActions } from "../../../features/password/passwordSlice";
import { IoIosLink as FPIcon } from "react-icons/io";

import { useAppTheme } from "../../../utils/hooks";
import { ReCaptcha } from "../ForgotPassword/re-captcha";
import { AuthTemplate } from "../AuthTemplate";
import { MessageBarNotification } from "../../Notification";
import { AppContext } from "../../../utils/context";

const forgotPasswordStyles = (theme: ITheme) =>
  mergeStyleSets({
    column2: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "250px",
      maxWidth: "250px",
      padding: "24px 20px",
      flexGrow: 7,
      backgroundColor: theme.palette?.white,
      borderRadius: "0 6px 6px 0",

      selectors: {
        "@media(max-width: 640px)": {
          display: "block",
          minWidth: "300px",
          background: "none",
          borderRadius: 0,
        },
      },
    },

    column2Top: {
      flexGrow: 1,
    },

    forgotPasswordBtn: {
      width: "100%",
      margin: "16px 0 0 0 !important",
      zIndex: 32000,
    },
    errorCaptcha: {
      fontSize: 12,
      fontWeight: 400,
      color: "rgb(164, 38, 44)",
      margin: "auto",
    },
  });

interface Props {
  // onLoginSuccess: () => void;
}
export const ForgotPasswordForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<number>(0);
  const { setMessage } = useContext(AppContext);

  const { handleSubmit, errors, control, setError } = useForm<{
    email: string;
  }>({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email().required("Email is required"),
      })
    ),
  });

  const shareForgotPasswordLink = useCallback(
    (email: string) =>
      dispatch(passwordActions.userForgotPasswordThunk(email)).then(
        (action) => {
          if (passwordActions.userForgotPasswordThunk.rejected.match(action)) {
            setError("email", {
              type: "notMatch",
              // @ts-ignore
              message: action?.payload?.message || "Error: Invalid Email",
            });
          } else {
            setTimer(60);
            setMessage({
              text:
                "ResetPassword Link is shared successfully to registered emailId.",
              type: MessageBarType.info,
            } as MessageBarNotification);
          }
        }
      ),
    [dispatch, setError, setMessage]
  );

  const onSubmit = ({ email }: { email: string }) => {
    if (!captchaState.isCaptchaSuccess) {
      setCaptchaState({ ...captchaState, isDisplayCaptchaError: true });
    } else if (!timer) {
      shareForgotPasswordLink(email);
    }
  };

  useEffect(() => {
    let timeRef: null | NodeJS.Timeout = null;
    if (timer) {
      timeRef = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    return () => {
      timeRef && clearTimeout(timeRef);
    };
  }, [timer]);

  const { selectedTheme } = useAppTheme();
  const classes = forgotPasswordStyles(selectedTheme as ITheme);
  const [captchaState, setCaptchaState] = useState<{
    isCaptchaSuccess: boolean;
    isDisplayCaptchaError: boolean;
  }>({ isCaptchaSuccess: false, isDisplayCaptchaError: false });

  return (
    <AuthTemplate
      heading={"Forgot Password"}
      subHeading={"Please enter valid email and captcha."}
      Icon={FPIcon}
    >
      <>
        {/* Right Body */}
        <form
          className={classes.column2}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className={classes.column2Top}>
            <Controller
              as={TextField}
              errorMessage={errors.email?.message}
              label="Email"
              name="email"
              control={control}
              defaultValue=""
              required
            />
            <ReCaptcha
              theme={selectedTheme as ITheme}
              onChange={(status: boolean) =>
                setCaptchaState({
                  isDisplayCaptchaError: false,
                  isCaptchaSuccess: status,
                })
              }
            />
          </div>
          <div>
            <PrimaryButton type="submit" className={classes.forgotPasswordBtn}>
              Sent Reset Link
            </PrimaryButton>
            {captchaState.isDisplayCaptchaError && (
              <Text className={classes.errorCaptcha}>
                Error: Invalid Captcha
              </Text>
            )}
            {timer ? (
              <Label style={{ color: selectedTheme.palette.black }}>
                Resend link in {timer} sec.
              </Label>
            ) : null}
          </div>
        </form>
      </>
    </AuthTemplate>
  );
};
