import React from "react";
import { LoginForm } from "./Login";
import { useHistory, useLocation } from "react-router-dom";

export type LocationState = {
  state: {
    [key: string]: string;
  };
};
export const LoginPage = () => {
  const history = useHistory();
  const location: LocationState = useLocation();
  return (
    <LoginForm
      loginState={location.state}
      onLoginSuccess={() => history.push("/orders")}
    />
  );
};
