import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { Layer } from "@fluentui/react/lib/Layer";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MessageBarNotification } from "./MessageBarNotification";
import NotificationMessageBar from "./NotificationMessageBar";

export const MessageBarProvider: React.FunctionComponent = (props) => {
  const message = useSelector<RootState, MessageBarNotification>(state => state.appsettings.message);

  return (
    <>
      {props.children}
      {message.text && (
        <Layer className="message-bar-layer">
          <Stack horizontal horizontalAlign="end">
            <NotificationMessageBar message={message} />
          </Stack>
        </Layer>
      )}
    </>
  );
};
