import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { User} from "../../types";

export type LoginFormData = {
  username: string;
  password: string;
};

export const userLoginThunk = createAsyncThunk(
  "users/login",
  async (logindata: LoginFormData, { rejectWithValue }) => {
    const data = new FormData();
    data.append("username", logindata.username);
    data.append("password", logindata.password);
    const res = await axios.post("/api/v1/users/auth/", data);
    return res.data;
  }
);

export const logoutUser = createAsyncThunk("users/logout", async () => {
  await axios.delete("/api/v1/users/auth/");
});

export const checkSession = createAsyncThunk("auth/check", async () => {
  const ret = await axios.get("/api/v1/users/auth/");
  return ret.data;
});

const userInfo = {} as User;

const slice = createSlice({
  name: "users",
  initialState: userInfo,
  reducers: {
    reset: (state) => {
      return userInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginThunk.pending, (state, action) => {})
      .addCase(userLoginThunk.fulfilled, (state, { payload }) => {
        state = payload || {};
      })
      .addCase(userLoginThunk.rejected, (state, action) => {
        return userInfo;
      })
      .addCase(checkSession.fulfilled, (state, {payload}) => {
        return payload
      })
      .addCase(checkSession.rejected, (state) => {
        return userInfo;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        return userInfo;
      })
  },
});

export default slice.reducer;
export const userActions = {
  ...slice.actions,
  userLoginThunk,
  checkSession,
  logoutUser
};

export const selectUser = (state: RootState) => state.user;