import React from "react";

import {
  IColumn,
  DetailsList,
  IDetailsListStyles,
  DetailsListLayoutMode,
  CheckboxVisibility,
  IDetailsHeaderProps,
  IRenderFunction,
  Sticky,
  StickyPositionType,
  ScrollablePane,
} from "office-ui-fabric-react";

import { Iannotation } from "../types";
interface Props {
  result: Array<Iannotation>;
}

export const SnomedTable: React.FC<Props> = ({ result }) => {
  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflowX: "scroll",
      selectors: {
        "& [role=grid]": {
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          height: "85vh",
        },
      },
    },
    headerWrapper: {
      flex: "0 0 auto",
    },
    contentWrapper: {
      flex: "1 1 auto",
      overflowY: "auto",
      overflowX: "hidden",
    },
  };

  const sortColumn = (index: number) => {
    const { sortIndex, columns, annotations } = tableState;
    // Default sorting order when column is first clicked

    // Toggle sorting order if column is clicked again
    if (sortIndex === index) {
      const col = columns[index];
      col.isSortedDescending = !col.isSortedDescending;
      col.isSorted = true;

      console.log("if console");
    } else {
      // Remove arrow UI from currently sorted column
      const prevCol = columns[sortIndex];
      prevCol.isSorted = false;
      delete prevCol.isSortedDescending;

      // Add arrow UI for the newly sorted column
      const nextCol = columns[index];
      nextCol.isSorted = true;
      nextCol.isSortedDescending = !nextCol.isSortedDescending;

      console.log("else console");
    }

    // Sort results wrt the new list.
    const { isSortedDescending, fieldName: columnKey } = columns[index];
    const key = columnKey as keyof Iannotation;

    console.log({ sortIndex, index });
    // Re-render
    setTableState({
      sortIndex: index,
      columns,
      annotations: annotations
        .slice(0)
        .sort((a: Iannotation, b: Iannotation) => {
          return isSortedDescending
            ? // @ts-ignore
              a[key].localeCompare(b[key], "en", { numeric: true })
            : // @ts-ignore
              b[key].localeCompare(a[key], "en", { numeric: true });
        }),
    });
  };

  const columns: IColumn[] = [
    {
      key: "code",
      name: "Code",
      fieldName: "code",
      minWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted 1 to 100",
      sortDescendingAriaLabel: "Sorted 100 to 1",
      onColumnClick: () => sortColumn(0),
      data: "number",
      isPadded: true,
    },
    {
      key: "preferredTerm",
      name: "Preferred Term",
      fieldName: "preferredTerm",
      minWidth: 210,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: () => sortColumn(1),
      data: "string",
      isPadded: true,
    },
  ];

  const [tableState, setTableState] = React.useState<{
    sortIndex: number;
    columns: IColumn[];
    annotations: Iannotation[];
  }>({ sortIndex: 0, columns, annotations: result });

  return (
    <DetailsList
      items={tableState.annotations}
      columns={tableState.columns}
      getKey={(item: any, index?: number): string => {
        return item.key;
      }}
      checkboxVisibility={CheckboxVisibility.hidden}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      isHeaderVisible={true}
      enterModalSelectionOnTouch={false}
      styles={gridStyles}
      onRenderDetailsHeader={(props, defaultRender) => {
        if (!props) return null;
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
              ...props,
            })}
          </Sticky>
        );
      }}
    />
  );
};
