import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import {
  default as userReducer,
  logoutUser,
} from "../features/users/usersSlice";
import { default as passwordReducer } from "../features/password/passwordSlice";
import { default as appsettingsReducer } from "../features/appsettings/appsettingsSlice";
import { default as termReducer } from "../features/term/termSlice";

const createRootReducer = () => {
  const allReducers = combineReducers({
    user: userReducer,
    password: passwordReducer,
    term: termReducer,
    appsettings: appsettingsReducer,
  });

  const appReducer = (state: any, action: AnyAction) => {
    if (logoutUser.fulfilled.match(action)) {
      state = undefined;
    }

    return allReducers(state, action);
  };

  return appReducer;
};

const rootReducer = createRootReducer();

export default rootReducer;
